import React, { ReactElement, useEffect, useState } from "react";
import { ReadHomebrew } from "../components/read_homebrew/ReadHomebrew";
import { useAlertContext } from "../context/Alert";
import { parseUrlParams } from "../Utils";

export default function s3(): ReactElement {
  const getKey = () => {
    return parseUrlParams(location.search).get("key") ?? "";
  };

  const { alert } = useAlertContext();

  return (
    <>
      {alert}
      <ReadHomebrew getKey={getKey} />;
    </>
  );
}
